export const metadataMap: Record<
  string,
  { title: string; description: string }
> = {
  "/authentication/login": {
    title: "Acesse a sua conta da Flash - Login",
    description:
      "Entre com seu CPF e senha na sua conta da Flash - software unificado de gestão de benefícios, pessoas e despesas com cartão corporativo.",
  },
};
