import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { loadWafScript } from "@flash-tecnologia/flashstage-auth";

import { initSentry } from "./config/sentry";
import microfrontendLayout from "./microfrontend-layout.html";

import "./css/main.css";
import "./css/normalize.css";
import { metadataMap } from "./config/seo/metadata";

initSentry();
loadWafScript();

const originalFetch = window.fetch;
const flashDomains = ["flashapp.site", "flashapp.dev", "flashapp.services"];

window.fetch = async function (input, config = {}, ...restArgs) {
  let shouldSendDeviceId = false;
  if (input.toString?.()) {
    const url = new URL(input.toString());
    shouldSendDeviceId = flashDomains.some((d) => url.hostname.includes(d));
  }
  let deviceId = window.localStorage.getItem("x-flash-device-id");
  if (!deviceId) {
    deviceId = crypto.randomUUID();
    window.localStorage.setItem("x-flash-device-id", deviceId);
  }

  config = config || {};
  config.headers = config.headers || {};
  config.headers["x-flash-origin"] = "flashos-web";
  if (shouldSendDeviceId) {
    config.headers["X-FLASH-DEVICE-ID"] = deviceId;
    config.headers["x-aws-waf-token"] = (
      window as any
    )?.AwsWafIntegration?.hasToken()
      ? await (window as any).AwsWafIntegration.getToken()
      : undefined;
  }

  return originalFetch(input, config, ...restArgs);
};

window.addEventListener("single-spa:routing-event", () => {
  const path = window.location.pathname;
  if (metadataMap[path]) {
    document.title = metadataMap[path].title;
    let metaDescription = document.querySelector("meta[name='description']");
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", metadataMap[path].description);
  }
});

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
